function addMaxLengthToTextAreas() {
  const textAreas = document.querySelectorAll("textarea[id]");
  const maxLength = 1000;
  textAreas.forEach((textArea) => {
    const initialLength = textArea.value.length;
    const counter = document.createElement("span");

    counter.innerHTML = `${initialLength} / ${maxLength}`;

    counter.style.display = "block";
    counter.style.textAlign = "right";
    counter.style.paddingTop = "10px";

    textArea.parentElement.appendChild(counter);

    const handleKeyup = (e) => {
      const length = e.target.value.length;
      counter.innerHTML = `${length} / ${maxLength}`;
    };

    textArea.addEventListener("keyup", handleKeyup);

    textArea.maxLength = maxLength;
  });
}

window.addEventListener('heyflow-init', addMaxLengthToTextAreas);